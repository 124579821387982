<template>
  <div class="flex flex-col">
    <div class="relative mb-4">
      <div class="py-2 text-left font-bold">{{ headerName }}</div>
      <p class="mb-2 text-xs italic text-black">
        {{ t('substances.panel.cutoffWithinParenthesis') }}
      </p>
      <div class="absolute bottom-0 left-0 right-0 h-[1px] bg-gray-300" />
    </div>
    <div
      class="mb-8 grid grid-cols-1 items-baseline gap-x-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      <SubstanceListItem
        v-for="substance in sortedSubstances"
        :key="substance.name"
        :substance="substance"
        :has-accredited-substance="hasAccreditedSubstance"
      />
    </div>
    <AccreditationLabInfo
      :has-qualitative-substance="hasQualitativeSubstance"
      :has-accredited-substance="hasAccreditedSubstance"
      :has-accredited-and-unaccredited-substance="
        hasAccreditedAndUnaccreditedSubstance
      "
    />
  </div>
</template>
<script setup lang="ts">
import AccreditationLabInfo from '@/components/AccreditationLabInfo.vue';
import SubstanceListItem from '@/components/SubstanceListItem.vue';
import { DoaPanelSize, Panels } from '@careos/toxicology-types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  headerName: string;
  substancesWithInfo: Panels[DoaPanelSize];
  hasAccreditedSubstance: boolean;
  hasAccreditedAndUnaccreditedSubstance: boolean;
  hasQualitativeSubstance: boolean;
}>();

const sortedSubstances = computed(() => {
  const substances = props.substancesWithInfo;
  return substances?.sort((a, b) => a.name.localeCompare(b.name));
});
</script>

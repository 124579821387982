<template>
  <div v-for="option in options" :key="option?.key">
    <SubstanceRadio
      v-if="store.panels && option"
      :data-test-id="`panel-size-${option.key}`"
      :title="option.value"
      :dialog-title="option.panelTextWithSuperscript || option.value"
      :is-selected-panel="selectedPanelSize === option.key"
      :panel-size="option.key"
      :panel-substances="store.panels[option.key] || []"
      :has-accredited-substance="
        hasPanelAccreditedSubstances(store.panels[option.key] || [])
      "
      :has-qualitative-substance="
        hasPanelQualitativeSubstances(store.panels[option.key] || [])
      "
      :has-accredited-and-unaccredited-substance="
        hasPanelAccreditedAndUnaccreditedSubstances(
          store.panels[option.key] || [],
          option.key,
        )
      "
      @update:selected-panel-size="updateSelectedOption"
    />
  </div>
</template>
<script setup lang="ts">
import { useOrderViewStore } from '@/views/order-view/store/order-view-store';

import {
  DoaPanelSize,
  DoaPanelSizeSchema,
  PanelSize,
} from '@careos/toxicology-types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { LabelBuilderArgs } from '../types/label-builder';
import SubstanceRadio from './SubstanceRadio.vue';
import {
  hasPanelAccreditedAndUnaccreditedSubstances,
  hasPanelQualitativeSubstances,
  hasPanelAccreditedSubstances,
} from '@/views/order-view/store/order-view-store-helpers';

const store = useOrderViewStore();
const { t } = useI18n();

defineProps<{
  selectedPanelSize: DoaPanelSize;
}>();

const getPanelLabel = ({
  substanceCount,
  panelSize,
  hasSwedacAccreditation,
}: LabelBuilderArgs) => {
  const panelSizeText = t(`substances.${panelSize}`);
  const panelSizeTextWithSuperscript = t(`substances.${panelSize}`, {
    superscript: panelSize === PanelSize.CHIRAL ? '¹' : '',
  });

  const substanceCountText = t(
    `substances.panel.allSubstances`,
    {
      substanceCount,
    },
    substanceCount,
  );
  const panelText = `${panelSizeText} (${substanceCountText})`;
  const panelTextWithSuperscript = hasSwedacAccreditation
    ? `${panelSizeTextWithSuperscript} (${substanceCountText})`
    : undefined;
  return { panelText, panelTextWithSuperscript };
};

const options = computed(() =>
  DoaPanelSizeSchema.options.map((it) => {
    if (!store.panels) return;

    const panel = store.panels[it];

    if (!panel) {
      return;
    }

    const substanceCount = panel.length;
    const hasSwedacAccreditation = panel.some((it) => it.isSwedacAccredited);
    const { panelText, panelTextWithSuperscript } = getPanelLabel({
      substanceCount,
      panelSize: it,
      hasSwedacAccreditation,
    });

    return {
      key: it as DoaPanelSize,
      value: panelText,
      panelTextWithSuperscript,
    };
  }),
);

const emits = defineEmits(['update:selectedPanelSize']);

const updateSelectedOption = (e: DoaPanelSize) => {
  emits('update:selectedPanelSize', e);
};
</script>
